<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Komisioner</label>
           <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            
            :options="komisionerOptions"
          />
            <small class="mt-1" style="color: red;" v-if="error.komisioner_id">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Tanggal Minutasi</label>
           <b-form-datepicker
              id="tgl_minutasi"
              autofocus
              trim
              placeholder="Tanggal Minutasi"

            />
            <small class="mt-1" style="color: red;" v-if="error.tgl_minutasi">{{error.message}}</small>
        </b-col>
         <!-- <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Tanggal</label>
            <label for="example-datepicker">Pilih Tanggal</label>
            <b-form-datepicker id="example-datepicker" v-model="localData.tgl_surat" class="mb-2" :locale="'id'"></b-form-datepicker>
        </b-col> -->
         <!-- <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Judul</label>
           <b-form-input
              id="judul"
              autofocus
              trim
              placeholder="Judul"
              v-model="localData.judul"
            />
            <small class="mt-1" style="color: red;" v-if="error.judul">{{error.message}}</small>
        </b-col> -->
         <!-- <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
            <b-media no-body>
              <b-media-body class="mt-60 ml-60">
                <label>File</label>
                <div class="d-flex" style="width: 100%;">
                  <b-form-file
                    v-if="!$route.params.readonly"
                    v-model="localData.file"
                    placeholder="Masukkan File"
                  >
                  </b-form-file>
                </div>
                <label class="ml-1" style="color: red;" v-if="!$route.params.readonly">Ukuran file max 5mb</label>
              </b-media-body>
            </b-media>
        </b-col> -->
         <b-col
          cols="12"
          md="6"
          class="mb-2 mt-2"
        >
            <b-button class="btn" variant="primary" @click="tambahData">Simpan</b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    tambahMinutasi,
    // uploadFileTandaTangan,
} from '@/connection-api/master'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
    tambahMinutasi,
  },
  data() {
    return {
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        jenis_surat_id: null,
        judul: null,
        nomor: null,
        file: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    jenisSuratOptions: {},
  },
  methods: {
    async tambahData()
    {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Minutasi',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: 'Berhasil menambahkan minutasi',
        },
      })
      // this.$emit('refetchData')
      // this.$emit('closeTambahData')
      // this.$router.replace(`/persidangan/mkh/${laporanId}`)
      setTimeout(() => {
  this.$router.go(0);
}, 3500);

    //   const response = await tambahMinutasi(this.localData)
    //   if(response.data.success === true)
    //   {
    //     this.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //           title: 'Minutasi',
    //           icon: 'CoffeeIcon',
    //           variant: 'success',
    //           text: response.data.message,
    //         },
    //       })
    //       this.localData.nama_negara = null
    //       this.localData.kode_negara = null
    //       this.$emit('refetchData')
    //       this.$emit('closeTambahData')
    //   }
    //   else
    //   {
    //     this.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //           title: 'Minutasi',
    //           icon: 'CoffeeIcon',
    //           variant: 'danger',
    //           text: response.data.message,
    //         },
    //       })
    //       this.error = response.data.error
    //   }
    },
    // async tambahData()
    // {
    //   if(this.localData.file !== undefined)
    //   {
    //     const responseUpload = await uploadFileTandaTangan(this.localData.file, event => {
    //       this.progress = Math.round((100 * event.loaded) / event.total)
    //     })
    //     this.localData.nama_file = responseUpload.data.file
    //   }

    //   this.localData.laporan_id = this.$route.params.laporan_id
    //   const response = await tambahTandaTangan(this.localData)
    //   if(response.data.success === true)
    //   {
    //     this.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //           title: 'Tanda',
    //           icon: 'CoffeeIcon',
    //           variant: 'success',
    //           text: response.data.message,
    //         },
    //       })
    //       this.$emit('refetchData')
    //   }
    //   else
    //   {
    //     this.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //           title: 'Surat',
    //           icon: 'CoffeeIcon',
    //           variant: 'danger',
    //           text: response.data.message,
    //         },
    //       })
    //       this.error = response.data.error
    //   }
    // },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
