<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Edit Data
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Komisioner</label>
           <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            
            :options="komisionerOptions"
          />
            <small class="mt-1" style="color: red;" v-if="error.komisioner_id">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Tanggal Minutasi</label>
           <b-form-datepicker
              id="tgl_minutasi"
              autofocus
              trim
              placeholder="Tanggal Minutasi"

            />
            <small class="mt-1" style="color: red;" v-if="error.tgl_minutasi">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2 mt-2"
        >
          <div class="d-flex">
            <b-button class="btn mr-1" variant="outline-primary" @click="close">Tutup</b-button>
            <b-button class="btn" variant="primary" @click="updateData">Simpan</b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  getMinutasiById,
  updateMinutasi,
  downloadSurat,
} from '@/connection-api/master'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
    getMinutasiById,
    updateMinutasi,
  },
  data() {
    return {
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    instansiId: {},
    showEditData: {},
    jenisSuratOptions: {},
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    async lihatData(file) {
        // const resp = await downloadSurat(file);
        // var type_file = file.substring(file.length - 4);
        // if (type_file === ".pdf") {
        //   this.imageFile = URL.createObjectURL(
        //     new Blob([resp.data], { type: "application/pdf" })
        //   );
        // } else {
        //   this.imageFile = URL.createObjectURL(
        //     new Blob([resp.data], { type: "image/jpeg" })
        //   );
        // }
        // window.open(this.imageFile);
    },
    async loadData() {
      // const response = await getSuratById(this.instansiId)
      // if(response.data.success === true)
      // {
      //   this.$toast({
      //     component: ToastificationContent,
      //     position: 'top-right',
      //     props: {
      //       title: 'Kelengkapan Data',
      //       icon: 'CoffeeIcon',
      //       variant: 'success',
      //       text: 'Data berhasil didapatkan!.',
      //     },
      //   })
      //   this.localData = response.data.data
      // }
    },
    close() {
      this.$emit('emitCloseEditData')
    },
    async updateData()
    {
      // const response = await updateMinutasi(this.localData)
      // if(response.data.success === true)
      // {
      //   this.$toast({
      //       component: ToastificationContent,
      //       position: 'top-right',
      //       props: {
      //         title: 'Minutasi',
      //         icon: 'CoffeeIcon',
      //         variant: 'success',
      //         text: response.data.message,
      //       },
      //     })
      //     this.$emit('refetchData')
      // }
      // else
      // {
      //   this.$toast({
      //       component: ToastificationContent,
      //       position: 'top-right',
      //       props: {
      //         title: 'Minutasi',
      //         icon: 'CoffeeIcon',
      //         variant: 'danger',
      //         text: response.data.message,
      //       },
      //     })
      //     this.error = response.data.error
      // }

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Minutasi',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: 'Berhasil mengubah minutasi',
        },
      })
      // this.$emit('refetchData')
      // this.$emit('closeTambahData')
      // this.$router.replace(`/persidangan/mkh/${laporanId}`)
      setTimeout(() => {
  this.$router.go(0);
}, 3500);
    },
    // async updateData()
    // {
    //   if(this.localData.file !== undefined)
    //   {
    //     const responseUpload = await uploadFileSurat(this.localData.file, event => {
    //       this.progress = Math.round((100 * event.loaded) / event.total)
    //     })
    //     this.localData.nama_file = responseUpload.data.file
    //   }

    //   this.localData.laporan_id = this.$route.params.laporan_id
    //   const response = await updateSurat(this.localData)
    //   if(response.data.success === true)
    //   {
    //     this.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //           title: 'Kelengkapan Data',
    //           icon: 'CoffeeIcon',
    //           variant: 'success',
    //           text: response.data.message,
    //         },
    //       })
    //       this.$emit('refetchData')
    //   }
    //   else
    //   {
    //     this.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //           title: 'Kelengkapan Data',
    //           icon: 'CoffeeIcon',
    //           variant: 'danger',
    //           text: response.data.message,
    //         },
    //       })
    //       this.error = response.data.error
    //   }
    // },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
