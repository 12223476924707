<template>
    <b-row>
      <b-col
        xl="12"
      >
        <h5 class="mb-2">Minutasi</h5>
        <b-form-group
          label="No Register"
          label-for="no_register"
        >
          <b-form-input
            id="no_register"
            trim
            placeholder=""
            :disabled="true"
         
          />
        </b-form-group>
     
       </b-col>
       
       <b-col xl="12" class="mt-3">
        <b-tabs pills >
          <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Minutasi</span>
              </template>
              <minutasi />
            </b-tab>
            <!-- <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kuasa Pelapor</span>
              </template>
              <kuasa-pelapor />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Terlapor</span>
              </template>
              <terlapor />
            </b-tab> -->
        </b-tabs>
       </b-col>
       <!-- <b-col xl="12" class="mt-3" v-if="localData.dokumen_lpp != null">
        <b-tabs pills>
          <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Dasar Pemeriksaan</span>
              </template>
              <dasar-pemeriksaan :dokumen_lpp="localData.dokumen_lpp" />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kasus Posisi</span>
              </template>
              <kasus-posisi />
            </b-tab>
          <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Pokok Laporan</span>
              </template>
              <pokok-laporan />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Tujuan Pemeriksaan</span>
              </template>
              <tujuan-pemeriksaan :dokumen_lpp="localData.dokumen_lpp"/>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Pelaksanaan Pemeriksaan</span>
              </template>
              <pelaksanaan-pemeriksaan :dokumen_lpp="localData.dokumen_lpp"/>
            </b-tab>
             <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Hasil Pemeriksaan</span>
              </template>
              <hasil-pemeriksaan :dokumen_lpp="localData.dokumen_lpp"/>
            </b-tab>
             <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Analisis</span>
              </template>
              <analisis />
            </b-tab>
             <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kesimpulan</span>
              </template>
              <kesimpulan />
            </b-tab>
             <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Tanda Tangan</span>
              </template>
              <tanda-tangan />
            </b-tab>
        </b-tabs>
       </b-col> -->
       <div class="d-flex align-items-center justify-content-end ml-auto mr-2 mb-4 mt-4">
        <!-- <b-button @onClick="tes()" variant="primary" >
              <span class="text-nowrap">Teruskan PSP ke Komisioner</span>
            </b-button> -->
            <!-- <b-button v-if="this.nilai_x === 0" v-b-modal="`modal-ke-komisioner`" variant="primary" >
              <span class="text-nowrap">Teruskan PSP ke Komisioner</span>
            </b-button> -->
            <b-button v-if="this.nilai_x === 0" v-b-modal="`modal-minutasi-selesai`" variant="primary" >
              <span class="text-nowrap">Minutasi Selesai</span>
            </b-button>
        
          </div>
          <!-- <b-modal :id="`modal-ke-komisioner`" centered title="Konfirmasi" @ok="keKomisioner()">
                  <div class="d-flex justify-content-center">
                    <h4 class="my-4">Apakah anda yakin ingin akan meneruskan laporan ke Komisioner?</h4>
                  </div>
          </b-modal> -->
          <b-modal :id="`modal-minutasi-selesai`" centered title="Konfirmasi" @ok="minutasiSelesai()">
                  <div class="d-flex justify-content-center">
                    <h4 class="my-4">Apakah anda yakin ingin akan menyelesaikan minutasi?</h4>
                  </div>
          </b-modal>
    </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormCheckbox,
  BTab,
  BTabs,
  BFormDatepicker,
  BFormTimepicker,
} from 'bootstrap-vue'
import {
  hapusPihak,
} from '@/connection-api/laporan'
import {
  updateVerifikasi,
  uploadFileLPP,
  downloadFileLPP,
  updateLPP,
} from '@/connection-api/master'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import ModalInstansi from "./ModalInstansi.vue"
import ModalNarahubung from "./ModalNarahubung.vue"
import Pelapor from '@/views/pelapor/list/List.vue'
import KuasaPelapor from '@/views/kuasa-pelapor/list/List.vue'
import Terlapor from '@/views/terlapor/list/List.vue'
import KasusPosisi from '@/views/kasus-posisi/list/List.vue'
import PokokLaporan from '@/views/pokok-laporan/list/List.vue'
import DataPendukung from '@/views/data-pendukung/list/List.vue'
import Analisis from '@/views/analisis/list/List.vue'
import Kesimpulan from '@/views/kesimpulan/list/List.vue'
import SaranAnalisis from '@/views/saran-analisis/list/List.vue'
// import TandaTangan from '@/views/tanda-tangan/list/List.vue'
import PertanyaanJawaban from '@/views/pertanyaan-jawaban/list/List.vue'
import DasarPemeriksaan from '@/views/dasar-pemeriksaan/list/List.vue'
import TujuanPemeriksaan from '@/views/tujuan-pemeriksaan/list/List.vue'
import PelaksanaanPemeriksaan from '@/views/pelaksanaan-pemeriksaan/list/List.vue'
import HasilPemeriksaan from '@/views/hasil-pemeriksaan/list/List.vue'
import Minutasi from '@/views/minutasi/list/List.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    ModalInstansi,
    ModalNarahubung,
    KasusPosisi,
     BFormCheckbox,
    BTab,
    BTabs,
    Pelapor,
    Terlapor,
    KuasaPelapor,
    PokokLaporan,
    DataPendukung,
    Analisis,
    Kesimpulan,
    SaranAnalisis,
    // TandaTangan,
    BFormDatepicker,
    BFormTimepicker,
    PertanyaanJawaban,
    DasarPemeriksaan,
    TujuanPemeriksaan,
    PelaksanaanPemeriksaan,
    HasilPemeriksaan,
    Minutasi,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  props: {
    localData: {},
    pemeriksaanOptions: {},
    jenisDokumenOptions: {},
    nilai_x: {},
  },
  methods: {
    // async tes(){
    //   console.log(this.nilai_x)
    // },
    async keKomisioner(){
      // console.log(localData.x)
      this.nilai_x = 1;
      // console.log(this.localData);
      // console.log(this.nilai_x);

      this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Putusan Sidang Pleno',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Minutasi berhasil diteruskan ke Komisioner',
                },
              })
    },
    async minutasiSelesai(){
      // console.log(localData.x)
      this.nilai_x = 2;
      // console.log(this.localData);
      // console.log(this.nilai_x);

      this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Putusan Sidang Pleno',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Minutasi telah selesai dan laporan telah disetujui',
                },
              })
    },
    async downloadDokumenLPP(){
      const result = await downloadFileLPP(this.localData.dokumen_lpp.nama_file)
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      const date = new Date().toLocaleString(['ban', 'id'])
      link.href = url
      link.setAttribute('download', `LPP-${date}.docx`)
      document.body.appendChild(link)
      link.click()
    },
    async simpanDokumen(){
      const responseUpload = await uploadFileLPP(this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        if(responseUpload.data.file != null)
        {
          this.localData.nama_file = responseUpload.data.file
          this.localData.laporan_id = this.$route.params.laporan_id
          const response = await updateLPP(this.localData)
          if(response.data.success === true)
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'LPP',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.localData.dokumen_lpp = response.data.data
          }
          else
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'LPP',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.error = response.data.message
          }
        } else {
           this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'LPP',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: responseUpload.data.message,
                },
              })
              this.error = responseUpload.data.message
        }
        
    },
    async update(params) {
      switch (params) {
        case 'investigasi-advokasi':
          this.localData.jenis_cta = "investigasi-advokasi";
          break;
        case 'pemeriksaan-pendahuluan':
          this.localData.jenis_cta = 'investigasi-advokasi';
          break;
      }
      
      this.localData.laporan_id = this.$route.params.laporan_id
      const response = await updateVerifikasi(this.localData)
      if(response.data.success === true)
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Analisis Laporan',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.$emit('refetchData')
              this.$router.push({name: 'tpl-analisis-laporan'})
          }
          else
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Analisis Laporan',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.error = response.data.error
          }
    },
    updateParaPihak() {
      this.$emit('emitUpdateParaPihak')
    },
    async hapusData(id){
      if(window.confirm('Apakah anda yakin?'))
      {
        const resp = await hapusPihak(id)
        if(resp.data.success === true)
        {
          this.makeToast('success', resp.data.message)
          this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
        }
      }
    },
    reloadDataKerjasama()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  }
}
</script>
